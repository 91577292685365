import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import ReactTable from 'react-table'
import moment from 'moment'
import { Container, Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col } from 'reactstrap'
import axios from 'axios'
import mimeDB from 'mime-db'
import { get, deleteItem, post } from '../utils/httpAgent'
import TextControl from '../components/text-control'
import DateTimeControl from '../components/datetime-control'
import SelectControl from '../components/select-control'
// import TextareaControl from '../components/textarea-control'
import Alert from '../shared/alert'
import Spinner from '../components/spinner'
import TextControlFillet from '../components/text-control-fillet'
import HButton from '../components/button'
import config from '../config'

class PersonnelsPage extends Component {
  constructor () {
    super()
    this.input = {}
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {},
      data: [],
      pageSize: 10,
      page: 0,
      show: false,
      showEntryMailModal: false,
      showEntryMail2Modal: false,
      entryMailLoading: false,
      targetId: '',
      targetName: '',
      search: ''
    }
    this.handleFetchData = this.handleFetchData.bind(this)
    this.handleToggle = this.handleToggle.bind(this)
    this.handleEntryMailToggle = this.handleEntryMailToggle.bind(this)
    this.handleSendPersonnel = this.handleSendPersonnel.bind(this)
    this.handleSendEntryMail = this.handleSendEntryMail.bind(this)

    this.handleEntryMail2Toggle = this.handleEntryMail2Toggle.bind(this)
    this.handleSendEntryMail2 = this.handleSendEntryMail2.bind(this)

    this.handleDownload = this.handleDownload.bind(this)
  }

  search () {
    this.handleFetchData({
      page: 0,
      pageSize: this.state.pageSize,
      search: this.state.search,
      sorted: []
    })
  }

  handleFetchData (state) {
    const page = state ? state.page + 1 : 0
    const limit = state ? state.pageSize : 10
    const search = this.state.search ? this.state.search : ''
    let sorted = state ? state.sorted : []

    if (sorted.length > 0) {
      sorted = `${sorted[0].desc ? '-' : ''}${sorted[0].id}`
    }

    this.setState({
      loading: true
    })

    get(`/1/admin/personnels?page=${page}&limit=${limit}&sort=${sorted}&search=${search}`)
      .then(r => {
        if (r.success === true) {
          r.data.forEach((item, i) => {
            item.updateTime = moment(item.updateTime).format('YYYY/MM/DD')
            item.createTime = moment(item.createTime).format('YYYY/MM/DD')
          })

          this.setState({
            loading: false,
            success: true,
            error: '',
            data: r.data,
            pages: r.pages.total
          })
        } else {
          const state = {
            loading: false,
            success: false,
            error: '',
            hasError: {},
            help: {}
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      })
  }

  deleteFetchFinancial (uid) {
    if (!window.confirm('是否確定要刪除此履歷？')) {
      return -1
    }

    deleteItem(`/1/admin/personnel/${uid}`)
      .then(r => {
        if (r.success === true) {
          this.handleFetchData()
        }
      })
  }

  handleToggle () {
    this.setState({
      show: !this.state.show,
      error: ''
    })
  }

  handleEntryMailToggle () {
    this.setState({
      showEntryMailModal: !this.state.showEntryMailModal,
      error: ''
    })
  }

  handleEntryMail2Toggle () {
    this.setState({
      showEntryMail2Modal: !this.state.showEntryMail2Modal,
      error: ''
    })
  }

  handleSendPersonnel (event) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      loading: true
    })

    post('/1/admin/personnel', {
      email: this.input.email.value()
    }).then(
      r => {
        if (r.success === true) {
          this.setState({
            success: true,
            error: '',
            loading: false,
            show: false
          })
          this.handleFetchData()
        } else {
          const state = {
            success: false,
            error: '',
            loading: false,
            hasError: {},
            help: {}
          }
          for (const key in r.errfor) {
            state.hasError[key] = true
            state.help[key] = r.errfor[key]
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      }
    )
  }

  handleSendEntryMail (event) {
    event.preventDefault()
    event.stopPropagation()
    this.setState({
      entryMailLoading: true
    })

    post(`/1/admin/personnel/${this.state.targetId}/entry1`, {
      checkInDay: this.state.checkInDay ? this.state.checkInDay.getTime() : '',
      employeeNo: this.input.employeeNo.value(),
      workPlace: this.input.workPlace.value(),
      department: this.input.department.value(),
      positionAgent1: this.input.positionAgent1.value(),
      positionAgent2: this.input.positionAgent2.value(),
      jobGrade: this.input.jobGrade.value(),
      jobTitle: this.input.jobTitle.value(),
      directSupervisor: this.input.directSupervisor.value(),
      firstLevelSupervisor: this.input.firstLevelSupervisor.value(),
      principalSalary: this.input.principalSalary.value(),
      subsistence: this.input.subsistence.value(),
      otherGiven: ''
    }).then(
      r => {
        if (r.success === true) {
          this.setState({
            success: true,
            error: '',
            entryMailLoading: false,
            showEntryMailModal: false,
            hasError: {},
            help: {},
            checkInDay: ''
          })
          this.handleFetchData()
        } else {
          const state = {
            success: false,
            error: '',
            entryMailLoading: false,
            hasError: {},
            help: {}
          }
          for (const key in r.errfor) {
            state.hasError[key] = true
            state.help[key] = r.errfor[key]
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      }
    )
  }

  handleSendEntryMail2 (event) {
    event.preventDefault()
    event.stopPropagation()
    this.setState({
      entryMailLoading: true
    })

    post(`/1/admin/personnel/${this.state.targetId}/entry2`, {
      checkInDay: this.state.checkInDay ? new Date(this.state.checkInDay).getTime() : this.input.checkInDay.value(),
      workPlace: this.state.workPlace ? this.state.workPlace : this.input.workPlace.value()
    }).then(
      r => {
        if (r.success === true) {
          this.setState({
            success: true,
            error: '',
            entryMailLoading: false,
            showEntryMail2Modal: false,
            hasError: {},
            help: {},
            checkInDay: '',
            workPlace: ''
          })
          this.handleFetchData()
        } else {
          const state = {
            success: false,
            error: '',
            entryMailLoading: false,
            hasError: {},
            help: {}
          }
          for (const key in r.errfor) {
            state.hasError[key] = true
            state.help[key] = r.errfor[key]
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      }
    )
  }

  handleDownload (pId, chName) {
    let uri = `/app/1/admin/personnel/${pId}/doc`

    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
      uri = `/1/admin/personnel/${pId}/doc`
    }

    axios({
      url: uri,
      responseType: 'blob',
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }).then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = downloadUrl
      let ext = mimeDB[data.type]
      if (ext && ext.extensions.length > 0) {
        ext = `.${ext.extensions[0]}`
      } else {
        ext = ''
      }
      link.setAttribute('download', `人事資料表_${chName}${ext}`)
      document.body.appendChild(link)
      link.click()
      link.remove()
      const key = {}
      key[pId] = false
      this.setState(key)
    }).catch(function (error) {
      console.log(error)
    })
  }

  handleDownloadEntry (pId, chName) {
    let uri = `/app/1/admin/personnel/${pId}/entry_doc`

    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
      uri = `/1/admin/personnel/${pId}/entry_doc`
    }

    axios({
      url: uri,
      responseType: 'blob',
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }).then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = downloadUrl
      let ext = mimeDB[data.type]
      if (ext && ext.extensions.length > 0) {
        ext = `.${ext.extensions[0]}`
      } else {
        ext = ''
      }
      link.setAttribute('download', `新進同仁基本資料建立申請表_${chName}${ext}`)
      document.body.appendChild(link)
      link.click()
      link.remove()
      const key = {}
      key[pId] = false
      this.setState(key)
    }).catch(function (error) {
      console.log(error)
    })
  }

  handleDownloadEmploymentConfirmation (pId, chName) {
    let uri = `/app/1/admin/personnel/${pId}/employment_confirmation`

    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
      uri = `/1/admin/personnel/${pId}/employment_confirmation`
    }

    axios({
      url: uri,
      responseType: 'blob',
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }).then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = downloadUrl
      let ext = mimeDB[data.type]
      if (ext && ext.extensions.length > 0) {
        ext = `.${ext.extensions[0]}`
      } else {
        ext = ''
      }
      link.setAttribute('download', `聘僱確認書_${chName}${ext}`)
      document.body.appendChild(link)
      link.click()
      link.remove()
      const key = {}
      key[pId] = false
      this.setState(key)
    }).catch(function (error) {
      console.log(error)
    })
  }

  handleHealthInsuranceEntry (pId, chName) {
    let uri = `/app/1/admin/personnel/${pId}/health_insurance_doc`

    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
      uri = `/1/admin/personnel/${pId}/health_insurance_doc`
    }

    axios({
      url: uri,
      responseType: 'blob',
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }).then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = downloadUrl
      let ext = mimeDB[data.type]
      if (ext && ext.extensions.length > 0) {
        ext = `.${ext.extensions[0]}`
      } else {
        ext = ''
      }
      link.setAttribute('download', `健保轉入申請表_${chName}${ext}`)
      document.body.appendChild(link)
      link.click()
      link.remove()
      const key = {}
      key[pId] = false
      this.setState(key)
    }).catch(function (error) {
      console.log(error)
    })
  }

  handleRaisingRelativesEntry (pId, chName) {
    let uri = `/app/1/admin/personnel/${pId}/raising_relatives_doc`

    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
      uri = `/1/admin/personnel/${pId}/raising_relatives_doc`
    }

    axios({
      url: uri,
      responseType: 'blob',
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }).then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = downloadUrl
      let ext = mimeDB[data.type]
      if (ext && ext.extensions.length > 0) {
        ext = `.${ext.extensions[0]}`
      } else {
        ext = ''
      }
      link.setAttribute('download', `薪資所得受領人扶養親屬申報表_${chName}${ext}`)
      document.body.appendChild(link)
      link.click()
      link.remove()
      const key = {}
      key[pId] = false
      this.setState(key)
    }).catch(function (error) {
      console.log(error)
    })
  }

  handleEmployeeConsentEntry (pId, chName) {
    let uri = `/app/1/admin/personnel/${pId}/employee_consent_doc`

    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
      uri = `/1/admin/personnel/${pId}/employee_consent_doc`
    }

    axios({
      url: uri,
      responseType: 'blob',
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }).then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = downloadUrl
      let ext = mimeDB[data.type]
      if (ext && ext.extensions.length > 0) {
        ext = `.${ext.extensions[0]}`
      } else {
        ext = ''
      }
      link.setAttribute('download', `員工同意書_${chName}${ext}`)
      document.body.appendChild(link)
      link.click()
      link.remove()
      const key = {}
      key[pId] = false
      this.setState(key)
    }).catch(function (error) {
      console.log(error)
    })
  }

  handleEmployeeGuaranteeEntry (pId, chName) {
    let uri = `/app/1/admin/personnel/${pId}/employee_guarantee_doc`

    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
      uri = `/1/admin/personnel/${pId}/employee_guarantee_doc`
    }

    axios({
      url: uri,
      responseType: 'blob',
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }).then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = downloadUrl
      let ext = mimeDB[data.type]
      if (ext && ext.extensions.length > 0) {
        ext = `.${ext.extensions[0]}`
      } else {
        ext = ''
      }
      link.setAttribute('download', `員工保證書_${chName}${ext}`)
      document.body.appendChild(link)
      link.click()
      link.remove()
      const key = {}
      key[pId] = false
      this.setState(key)
    }).catch(function (error) {
      console.log(error)
    })
  }

  handleEmployeeInfoSecurityPolicyDoc (pId, chName) {
    let uri = `/app/1/admin/personnel/${pId}/employee_info_security_policy_doc`

    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
      uri = `/1/admin/personnel/${pId}/employee_info_security_policy`
    }

    axios({
      url: uri,
      responseType: 'blob',
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }).then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = downloadUrl
      let ext = mimeDB[data.type]
      if (ext && ext.extensions.length > 0) {
        ext = `.${ext.extensions[0]}`
      } else {
        ext = ''
      }
      link.setAttribute('download', `員工資訊安全政策同意書_${chName}${ext}`)
      document.body.appendChild(link)
      link.click()
      link.remove()
      const key = {}
      key[pId] = false
      this.setState(key)
    }).catch(function (error) {
      console.log(error)
    })
  }

  handleEmployeeReportDoc (pId, chName) {
    let uri = `/app/1/admin/personnel/${pId}/report_doc`

    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
      uri = `/1/admin/personnel/${pId}/report_doc`
    }

    axios({
      url: uri,
      responseType: 'blob',
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }).then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = downloadUrl
      let ext = mimeDB[data.type]
      if (ext && ext.extensions.length > 0) {
        ext = `.${ext.extensions[0]}`
      } else {
        ext = ''
      }
      link.setAttribute('download', `員工報到證件_${chName}${ext}`)
      document.body.appendChild(link)
      link.click()
      link.remove()
      const key = {}
      key[pId] = false
      this.setState(key)
    }).catch(function (error) {
      console.log(error)
    })
  }

  render () {
    let alert = []
    const columns = [
      {
        Header: '下載',
        accessor: '_id',
        width: 55,
        Cell: row => (
          <button className='btn btn-sm btn-outline-primary' onClick={e => this.handleDownload(row.value, row.original.chName)}>
            <i className='lnr lnr-download' />
          </button>
        )
      }, {
        Header: '中文名',
        accessor: 'chName'
      }, {
        Header: 'email',
        accessor: 'email'
      }, {
        Header: '永久電話',
        accessor: 'permanentNumber'
      }, {
        Header: '應徵職位',
        accessor: 'applyPosition'
      // }, {
      //   Header: '性別',
      //   accessor: 'gender',
      //   width: 50
      }, {
        Header: '填寫狀況',
        accessor: 'isActive',
        width: 80,
        Cell: row => (
          <span>
            <span style={{
              color: row.value === true ? '#0000FF'
                : row.value === false ? '#FF0000'
                  : '#000',
              transition: 'all .3s ease'
            }}
            >
              &#x25cf;
            </span>&nbsp;
            {row.value === true ? '未完成'
              : row.value === false ? '已完成'
                : '異常狀態'}
          </span>
        )
      }, {
        Header: '更新日期',
        accessor: 'updateTime',
        width: 100
      }, {
        Header: '建立日期',
        accessor: 'createTime',
        width: 100
      }, {
        Header: '',
        accessor: '_id',
        width: 50,
        Cell: row => (
          <button className='btn btn-danger btn-sm btn-sm' onClick={this.deleteFetchFinancial.bind(this, row.value)}>
            <i className='lnr lnr-cross' />
          </button>
        )
      }]

    if (this.state.error) {
      alert = (
        <Col md={12}>
          <Alert
            type='danger'
            message={this.state.error}
          />
        </Col>
      )
    }

    return (
      <Container>
        <Helmet>
          <title>
            履歷管理
          </title>
        </Helmet>

        <Modal
          isOpen={this.state.show}
          toggle={this.handleToggle}
        >
          <ModalHeader toggle={this.handleToggle}>
            寄送人事資料表
          </ModalHeader>
          <ModalBody>
            <Row>
              {alert}
              <Col md={12}>
                <TextControl
                  ref={(c) => (this.input.email = c)}
                  name='email'
                  label='email'
                  placeholder='應徵者的 email'
                  hasError={this.state.hasError.email}
                  help={this.state.help.email}
                  disabled={this.state.loading}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color='primary' onClick={this.handleSendPersonnel}>寄出</Button>
            <Button color='secondary' className='ml-1' onClick={this.handleToggle}>取消</Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.showEntryMailModal}
          toggle={this.handleEntryMailToggle}
        >
          <ModalHeader toggle={this.handleEntryMailToggle}>
            寄送聘僱第一階段信件給{this.state.targetName}
          </ModalHeader>
          <ModalBody>
            <Row>
              {alert}
              <Col md={12}>
                <DateTimeControl
                  ref={(c) => (this.input.checkInDay = c)}
                  name='checkInDay'
                  label='報到日(到職日)'
                  timeFormat={false}
                  value={this.state.checkInDay}
                  onChange={(e) => this.setState({ checkInDay: new Date(e.valueOf()) })}
                  hasError={this.state.hasError.checkInDay}
                  help={this.state.help.checkInDay}
                  disabled={this.state.entryMailLoading}
                />
                <TextControl
                  ref={(c) => (this.input.employeeNo = c)}
                  name='employeeNo'
                  label='員工編號'
                  hasError={this.state.hasError.employeeNo}
                  help={this.state.help.employeeNo}
                  disabled={this.state.entryMailLoading}
                />
                <TextControl
                  ref={(c) => (this.input.jobGrade = c)}
                  name='jobGrade'
                  label='職等'
                  hasError={this.state.hasError.jobGrade}
                  help={this.state.help.jobGrade}
                  disabled={this.state.entryMailLoading}
                />
                <TextControl
                  ref={(c) => (this.input.jobTitle = c)}
                  name='jobTitle'
                  label='職稱'
                  hasError={this.state.hasError.jobTitle}
                  help={this.state.help.jobTitle}
                  disabled={this.state.entryMailLoading}
                />
                <SelectControl
                  ref={(c) => (this.input.workPlace = c)}
                  name='workPlace'
                  label='工作地點'
                  onChange={(e) => (this.setState({ workPlace: e.target.value }))}
                  hasError={this.state.hasError.workPlace}
                  help={this.state.help.workPlace}
                  disabled={this.state.entryMailLoading}
                >
                  <option value='新竹'>
                    新竹
                  </option>
                  <option value='台北'>
                    台北
                  </option>
                  <option value='高雄'>
                    高雄
                  </option>
                </SelectControl>
                <TextControl
                  ref={(c) => (this.input.department = c)}
                  name='department'
                  label='部門'
                  hasError={this.state.hasError.department}
                  help={this.state.help.department}
                  disabled={this.state.entryMailLoading}
                />
                <TextControl
                  ref={(c) => (this.input.directSupervisor = c)}
                  name='directSupervisor'
                  label='直屬主管'
                  hasError={this.state.hasError.directSupervisor}
                  help={this.state.help.directSupervisor}
                  disabled={this.state.entryMailLoading}
                />
                <TextControl
                  ref={(c) => (this.input.firstLevelSupervisor = c)}
                  name='firstLevelSupervisor'
                  label='一級主管'
                  hasError={this.state.hasError.firstLevelSupervisor}
                  help={this.state.help.firstLevelSupervisor}
                  disabled={this.state.entryMailLoading}
                />
                <TextControl
                  ref={(c) => (this.input.positionAgent1 = c)}
                  name='positionAgent1'
                  label='職務代理人第一順位'
                  hasError={this.state.hasError.positionAgent1}
                  help={this.state.help.positionAgent1}
                  disabled={this.state.entryMailLoading}
                />
                <TextControl
                  ref={(c) => (this.input.positionAgent2 = c)}
                  name='positionAgent2'
                  label='職務代理人第二順位'
                  hasError={this.state.hasError.positionAgent2}
                  help={this.state.help.positionAgent2}
                  disabled={this.state.entryMailLoading}
                />
                <TextControl
                  ref={(c) => (this.input.principalSalary = c)}
                  name='principalSalary'
                  label='本薪'
                  hasError={this.state.hasError.principalSalary}
                  help={this.state.help.principalSalary}
                  disabled={this.state.entryMailLoading}
                />
                <TextControl
                  ref={(c) => (this.input.subsistence = c)}
                  name='subsistence'
                  label='伙食津貼'
                  hasError={this.state.hasError.subsistence}
                  help={this.state.help.subsistence}
                  disabled={this.state.entryMailLoading}
                />
                {/* <TextareaControl
                  ref={(c) => (this.input.otherGiven = c)}
                  name='otherGiven'
                  label='其他給予'
                  rows='10'
                  hasError={this.state.hasError.otherGiven}
                  help={this.state.help.otherGiven}
                  disabled={this.state.loading}
                /> */}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color='primary' onClick={this.handleSendEntryMail}>寄出</Button>
            <Button color='secondary' className='ml-1' onClick={this.handleEntryMailToggle}>取消</Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.showEntryMail2Modal}
          toggle={this.handleEntryMail2Toggle}
        >
          <ModalHeader toggle={this.handleEntryMail2Toggle}>
            寄送聘僱第二階段信件給{this.state.targetName}
          </ModalHeader>
          <ModalBody>
            <Row>
              {alert}
              <Col md={12}>
                <DateTimeControl
                  ref={(c) => (this.input.checkInDay = c)}
                  name='checkInDay'
                  label='報到日(到職日)'
                  timeFormat={false}
                  value={this.state.checkInDay}
                  onChange={(e) => this.setState({ checkInDay: new Date(e.valueOf()) })}
                  hasError={this.state.hasError.checkInDay}
                  help={this.state.help.checkInDay}
                  disabled={this.state.entryMailLoading}
                />
                <SelectControl
                  ref={(c) => (this.input.workPlace = c)}
                  name='workPlace'
                  label='工作地點'
                  value={this.state.workPlace}
                  onChange={(e) => (this.setState({ workPlace: e.target.value }))}
                  hasError={this.state.hasError.workPlace}
                  help={this.state.help.workPlace}
                  disabled={this.state.entryMailLoading}
                >
                  <option value='新竹'>
                    新竹
                  </option>
                  <option value='台北'>
                    台北
                  </option>
                  <option value='高雄'>
                    高雄
                  </option>
                </SelectControl>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color='primary' onClick={this.handleSendEntryMail2}>寄出</Button>
            <Button color='secondary' className='ml-1' onClick={this.handleEntryMail2Toggle}>取消</Button>
          </ModalFooter>
        </Modal>

        <h1 className='page-header'>履歷管理</h1>
        <Row>
          <Col md={12} lg={12} xl={12}>
            <Button color='success' onClick={e => { this.setState({ show: true }) }}>寄送人事資料表</Button>
            <TextControlFillet
              ref={(c) => (this.input.search = c)}
              groupClasses={{ 'form-group-inline max-width-125 ml-5': true }}
              name='search'
              value={this.state.search}
              placeholder='中文名、email、獵人頭帳號'
              onChange={(e) => {
                this.setState({ search: e.target.value })
                this.search.bind(this)
              }}
              disabled={this.state.loading}
            />
            &nbsp;
            <HButton
              type='button'
              inputClasses={{ 'btn btn-primary ml-1 mb-1': true }}
              disabled={this.state.loading}
              onClick={this.search.bind(this)}
            >
              搜尋
              <Spinner space='left' show={this.state.loading} />
            </HButton>
          </Col>
          <div className='col-md-12'>
            <ReactTable
              manual
              data={this.state.data}
              pages={this.state.pages}
              loading={this.state.loading}
              onFetchData={this.handleFetchData}
              columns={columns}
              SubComponent={row => (
                <div className='row' style={{ padding: '20px' }}>
                  <div className='col-md-12' style={{ padding: '20px' }}>
                    {row.original.hunterNo &&
                      <p>負責單位：{row.original.hunterNo}</p>}
                    {row.original.photo &&
                      <img alt='Crop' style={{ maxWidth: '100%' }} src={`${config.awsUrl}${row.original.photo}`} />}
                  </div>
                  <div className='col-md-12' style={{ padding: '20px' }}>
                    <Button
                      color='success'
                      className='mr-2'
                      onClick={e => {
                        this.setState({
                          showEntryMailModal: true,
                          targetId: row.original._id,
                          targetName: row.original.chName
                        })
                      }}
                    >
                      寄送聘僱第一階段信件
                    </Button>
                    <Button
                      color='success'
                      onClick={e => {
                        this.setState({
                          showEntryMail2Modal: true,
                          targetId: row.original._id,
                          targetName: row.original.chName,
                          workPlace: row.original.entryInfo.workPlace,
                          checkInDay: row.original.entryInfo.checkInDay ? new Date(row.original.entryInfo.checkInDay).getTime() : ''
                        })
                      }}
                    >
                      寄送聘僱第二階段信件
                    </Button>
                  </div>
                  {row.original.hasEntryMail &&
                    <table className='table table-striped'>
                      <thead>
                        <tr>
                          <th scope='col'>下載</th>
                          <th scope='col'>文件</th>
                          <th scope='col'>填寫狀況</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>
                            {row.original.employmentConfirmation.isComplete &&
                              <button className='btn btn-sm btn-outline-primary' onClick={e => this.handleDownloadEmploymentConfirmation(row.original._id, row.original.chName)}>
                                <i className='lnr lnr-download' />
                              </button>}
                          </th>
                          <td>I. 聘僱確認書</td>
                          <td>{row.original.employmentConfirmation.isComplete ? '完成' : '未完成'}</td>
                        </tr>
                        <tr>
                          <th>
                            {row.original.entryInfo.isComplete &&
                              <button className='btn btn-sm btn-outline-primary' onClick={e => this.handleDownloadEntry(row.original._id, row.original.chName)}>
                                <i className='lnr lnr-download' />
                              </button>}
                          </th>
                          <td>I. 新進同仁基本資料表</td>
                          <td>{row.original.entryInfo.isComplete ? '完成' : '未完成'}</td>
                        </tr>
                        <tr>
                          <th>
                            {row.original.healthInsurance.isComplete &&
                              <button className='btn btn-sm btn-outline-primary' onClick={e => this.handleHealthInsuranceEntry(row.original._id, row.original.chName)}>
                                <i className='lnr lnr-download' />
                              </button>}
                          </th>
                          <td>II. 全民健康保險投保(轉入)申請表</td>
                          <td>{row.original.healthInsurance.isComplete ? '完成' : '未完成'}</td>
                        </tr>
                        <tr>
                          <th>
                            {row.original.raisingRelatives.isComplete &&
                              <button className='btn btn-sm btn-outline-primary' onClick={e => this.handleRaisingRelativesEntry(row.original._id, row.original.chName)}>
                                <i className='lnr lnr-download' />
                              </button>}
                          </th>
                          <td>II. 薪資所得受領人扶養親屬申報表</td>
                          <td>{row.original.raisingRelatives.isComplete ? '完成' : '未完成'}</td>
                        </tr>
                        <tr>
                          <th>
                            {row.original.employeeConsent.isComplete &&
                              <button className='btn btn-sm btn-outline-primary' onClick={e => this.handleEmployeeConsentEntry(row.original._id, row.original.chName)}>
                                <i className='lnr lnr-download' />
                              </button>}
                          </th>
                          <td>II. 員工同意書</td>
                          <td>{row.original.employeeConsent.isComplete ? '完成' : '未完成'}</td>
                        </tr>
                        <tr>
                          <th>
                            {row.original.employeeGuarantee.isComplete &&
                              <button className='btn btn-sm btn-outline-primary' onClick={e => this.handleEmployeeGuaranteeEntry(row.original._id, row.original.chName)}>
                                <i className='lnr lnr-download' />
                              </button>}
                          </th>
                          <td>II. 員工保證書</td>
                          <td>{row.original.employeeGuarantee.isComplete ? '完成' : '未完成'}</td>
                        </tr>
                        <tr>
                          <th>
                            {row.original.employeeInfoSecurityPolicy?.isComplete &&
                              <button className='btn btn-sm btn-outline-primary' onClick={e => this.handleEmployeeInfoSecurityPolicyDoc(row.original._id, row.original.chName)}>
                                <i className='lnr lnr-download' />
                              </button>}
                          </th>
                          <td>II. 員工資訊安全政策同意書</td>
                          <td>{row.original.employeeInfoSecurityPolicy && row.original.employeeInfoSecurityPolicy.isComplete ? '完成' : '未完成'}</td>
                        </tr>
                        <tr>
                          <th>
                            {row.original.reportDocuments?.isComplete &&
                              <button className='btn btn-sm btn-outline-primary' onClick={e => this.handleEmployeeReportDoc(row.original._id, row.original.chName)}>
                                <i className='lnr lnr-download' />
                              </button>}
                          </th>
                          <td>II. 員工報到繳交文件</td>
                          <td>{row.original.reportDocuments && row.original.reportDocuments.isComplete ? '完成' : '未完成'}</td>
                        </tr>
                      </tbody>
                    </table>}
                </div>
              )}
              previousText='上一頁'
              nextText='下一頁'
              pageText='頁'
              ofText='/'
              rowsText='筆'
              noDataText='目前尚無任何資料'
              className='-striped -highlight'
              defaultPageSize={this.state.pageSize}
              onPageSizeChange={(pageSize) => (this.setState({ pageSize: pageSize }))}
              defaultSorted={[{ id: 'updateTime', desc: true }]}
            />
          </div>
        </Row>
      </Container>
    )
  }
}

export default PersonnelsPage
